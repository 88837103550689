export type RegionCodes = string;

export const regionCodes: readonly RegionCodes[] = Object.freeze([
  "IT-21",
  "IT-23",
  "IT-25",
  "IT-32-BZ",
  "IT-32-TN",
  "IT-34",
  "IT-36",
  "IT-57",
]);

export const regionsRegex = /^(IT-[0-9]{2})/;

// NOTE: c2586e05-2984-4707-b73b-5a343ec0566f The above regex is not effective to skip MeteoMont.